<template>
    <div>
      <!-- <van-cell>
        <template slot="title">
          <span style="color:#57c4b7;">*温馨提示：请选择成员来进行报告查询</span>
        </template>
      </van-cell>
      <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
        <van-cell-group>
            <van-cell v-for="item in list" :key="item+'RCardL'" :title="item.userName" :value="item.cardNo" @click="onclick(item.userName,item.idCard,item.cardNo)"></van-cell>
        </van-cell-group>
        
      </van-list> -->
      <div style="text-align:center"><van-image
      style="margin: 45% 35% 3% 35%;"
      width="5rem"
      height="5rem"
      fit="cover"
      :src="newImg"
    />
      </div>
    <div style="font-size: 18px; color: #D3D3D3; width: auto; height: 40px; text-align:center"><span>正在努力建设中</span></div>
    </div>
</template>
<script>
import ajax from '../../lib/ajax';
import { Toast } from 'vant';
import store from '../../store';
// var lists;
  export default {
    data() {
      return {
        list:[],
        // list: [{
        //   PatientName: "李子",
        //   CardNumber: "123456467",
        //   Idcard:"492527199505142222",
        // },
        // {
        //   PatientName: "王往",
        //   CardNumber: "5445645646",
        //   Idcard:"532527199505142222",
        // }],
        newImg: require("../../assets/err/404.png"),
        loading: false,
        finished: false
      };
    },

    methods: {
      async onLoad() {
        // 异步更新数据
        setTimeout(() => {
          for (let i = 0; i < 0; i++) {
            this.list.push(this.list.length);
          }
          // 加载状态结束
          this.loading = false;

          // 数据全部加载完成
          if (this.list.length == this.list.length) {
            this.finished = true;
          }
        }, 500);
        // await ajax.get("/Api/CardService/WeChatGetOwnerMembers",{
        //   wxOpenId: wxopenid,
        // }).then((res)=>{
        //   lists = JSON.stringify(res.data.result);
        //   Toast(lists);
        // }).catch(function(){
        //   Toast("请求失败，请稍后重试！");
        // })
        let wxopenid = store.getters["wechat/openid"];
        await ajax.get("/Api/CardService/WeChatGetMembers?input="+wxopenid)
        .then((res) => {
          const lists = res.data.result;
          this.list = lists;
          console.log(lists);
        }).catch((err) => {
          console.log(err.data);
          Toast("获取失败，请稍后重试！");
        })
      },
      async onclick(userName,idCard,cardNo) {
        // alert(Idcard);
        // alert(outPatientId);
        store.commit("store/setUserName",userName);
        store.commit("store/setIdcard",idCard);
        store.commit("store/setCardNo",cardNo);
        // this.$store.dispatch('setsyncu',userName);
        this.$router.replace({
          //path:'/DepS/:Idcard',
          path:'/RepL',
        }).catch(err => {
                console.log(err);
        });
      }
    }
  }
</script>

<style>
</style>